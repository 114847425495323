import React from "react";

function Header({ active, setActive, categories }) {
  return (
    <section className="header">
      {categories.map((category) => (
        <button
          key={category}
          className={`${active === category ? "active" : ""}`}
          onClick={() => setActive(category)}
        >
          {category}
        </button>
      ))}

    </section>
  );
}

export default Header;
