import React from "react";
import { Galleria } from "primereact/galleria";
import { Carousel } from "primereact/carousel";
import { getLocalImageByName } from "../data";
function HeroSection({ pages }) {
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];
  const thumbnailTemplate = (media) => {
    const src = media.type === "video" ? media.poster : media.src;
    return (
      <img
        src={
          src.startsWith("http")
            ? src
            : getLocalImageByName(src)
        }
        alt={src}
        style={{ width: "100%", display: "block" }}
        loading="lazy"
      />
    );
  };
  const imgTemplate = (media) => {
    return media.type === "video" ? (
      <iframe
        title={media.title || ""}
        src={media.src}
        width="1920"
        height="1080"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
      ></iframe>
    ) : (
      <img
        key={media.src}
        className="animated-image"
        src={
          media.src.startsWith("http")
            ? media.src
            : getLocalImageByName(media.src)
        }
        alt={media.src}
        style={{ width: "100%", display: "block" }}
        loading="lazy"
      />
    );
  };

  const pageTemplate = (page) => {
    return (
      <section className="page-item " key={page.title}>
        <article className="left">
          <h3>{page.title}</h3>
          <p>{page.description}</p>
        </article>
        <article className="right">
          <Galleria
            circular
            value={page.media}
            numVisible={5}
            item={imgTemplate}
            thumbnail={thumbnailTemplate}
            // responsiveOptions={responsiveOptions}
            showThumbnails={page.media.length > 1}
            showThumbnailNavigators={page.media.length > 5}
            // autoPlay
            // transitionInterval={2000}
          />
        </article>
      </section>
    );
  };
  return (
    <section className="page-container">
      <Carousel
        circular={pages.length > 1}
        value={pages}
        numVisible={1}
        itemTemplate={pageTemplate}
        showIndicators={false}
        showNavigators={pages.length > 1}
      />
    </section>
  );
}

export default HeroSection;
