import React from "react";
import Navbar from "react-bootstrap/Navbar";
import logo from "../logo.gif";
import Nav from "react-bootstrap/Nav";
function NavbarComponent({ active, setActive, categories }) {
  const changeActiveSection = (category) => {
    setActive(category);
  };
  return (
    <section className="nav-bar">
      <Navbar sticky="top" collapseOnSelect expand="lg">
        <Navbar.Brand>
          <img src={logo} alt={"logo"} />
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <Navbar.Collapse className="justify-content-end">
          {categories.map((category) => (
            <Nav.Link
              href="#"
              className={{ "nav-item": true, active: active === category }}
              key={category}
              onClick={() => changeActiveSection(category)}
            >
              {category}
            </Nav.Link>
          ))}
        </Navbar.Collapse>
      </Navbar>
    </section>
  );
}

export default NavbarComponent;
