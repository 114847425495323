import React, { useState } from "react";
import data from "./data";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import Header from "./components/HeaderSection";
function App() {
  const categories = Object.keys(data);
  const [active, setActive] = useState(categories[0]);
  return (
    <section className="App">
      <Navbar {...{ active, setActive, categories }} />
      <article>
      <Header {...{ active, setActive, categories }}/>
      <HeroSection {...data[active]} />
      </article>
    </section>
  );
}

export default App;
