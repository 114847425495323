/*
  > media
    { type: "img", src: `image source` },
    OR
      {
            type: "video",
            title: "title of video",
            poster: "poster image source",
            src: `video link`,
          },
*/

const data = {
  Booths: {
    pages: [
      {
        title: `Allergan Aesthetics Saudi Booth`,
        description: ``,
        media: [
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/2.jpg` },
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/1.jpg` },
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/3.jpg` },
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/4.jpg` },
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/5.jpg` },
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/6.jpg` },
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/7.jpeg` },
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/8.jpeg` },
          { type: "img", src: `Booths/Allergan-Aesthetics-Saudi-Booth/9.jpeg` },
          {
            type: "img",
            src: `Booths/Allergan-Aesthetics-Saudi-Booth/10.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Allergan-Aesthetics-Saudi-Booth/11.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Allergan-Aesthetics-Saudi-Booth/12.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Allergan-Aesthetics-Saudi-Booth/13.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Allergan-Aesthetics-Saudi-Booth/14.jpeg`,
          },
        ],
      },

      {
        title: `Allergan Scholarship ATLAS`,
        description: ``,
        media: [
          {
            type: "img",
            src: `Booths/Allergan-Scholarship-ATLAS/1.jpg`,
          },
        ],
      },

      {
        title: `Forxiga CKD Launch`,
        description: ``,
        media: [
          { type: "img", src: `Booths/Forxiga-CKD-Launch/1.jpg` },
          { type: "img", src: `Booths/Forxiga-CKD-Launch/2.jpg` },
          { type: "img", src: `Booths/Forxiga-CKD-Launch/3.jpg` },
          { type: "img", src: `Booths/Forxiga-CKD-Launch/4.jpg` },
          { type: "img", src: `Booths/Forxiga-CKD-Launch/5.jpg` },
        ],
      },

      {
        title: `Abbvie KSA Booth`,
        description: ``,
        media: [
          { type: "img", src: `Booths/Abbvie-KSA-Booth/1.jpg` },
          { type: "img", src: `Booths/Abbvie-KSA-Booth/2.jpg` },
          { type: "img", src: `Booths/Abbvie-KSA-Booth/3.jpg` },
        ],
      },

      // {
      //   title: `Lilly-Cycle-Meeting`,
      //   description: ``,
      //   media: [
      //     { type: "img", src: `Booths/Lilly-Cycle-Meeting/1.jpg` },
      //     { type: "img", src: `Booths/Lilly-Cycle-Meeting/2.jpg` },
      //   ],
      // },

      {
        title: `Umbro Booth`,
        description: ``,
        media: [
          { type: "img", src: `Booths/Umbro-Booth/1.jpg` },
          { type: "img", src: `Booths/Umbro-Booth/2.jpg` },
          { type: "img", src: `Booths/Umbro-Booth/3.jpg` },
          { type: "img", src: `Booths/Umbro-Booth/4.jpg` },
          { type: "img", src: `Booths/Umbro-Booth/5.jpg` },
          { type: "img", src: `Booths/Umbro-Booth/6.jpg` },
          { type: "img", src: `Booths/Umbro-Booth/7.jpg` },
          { type: "img", src: `Booths/Umbro-Booth/8.jpg` },
        ],
      },
      {
        title: `Lilly Rheumatology Conference - Al Madinah`,
        description: ``,
        media: [
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/1.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/2.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/3.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/4.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/5.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/6.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/7.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/8.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Rheumatology Conference - Al Madinah/9.jpeg`,
          },
        ],
      },
      {
        title: `Synagis booth 1`,
        description: ``,
        media: [
          {
            type: "img",
            src: `Booths/Synagis booth-1/1.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth-1/2.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth-1/3.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth-1/4.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth-1/5.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth-1/6.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth-1/7.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth-1/8.jpeg`,
          },
          {
            type: "video",
            title: "event.mp4",
            poster: "events/moh-asthma-program/1.jpg",
            src: `https://player.vimeo.com/video/491189539`,
          },
        ],
      }, {
        title: `Lilly Booth`,
        description: ``,
        media: [
          {
            type: "img",
            src: `Booths/Lilly Booth/1.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Booth/2.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Booth/3.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Lilly Booth/4.jpeg`,
          },

        ],
      }, {
        title: `AstraZeneca booth`,
        description: ``,
        media: [
          {
            type: "img",
            src: `Booths/AstraZeneca booth/1.jpeg`,
          },
          {
            type: "img",
            src: `Booths/AstraZeneca booth/2.jpeg`,
          },
          {
            type: "img",
            src: `Booths/AstraZeneca booth/3.jpeg`,
          },
          {
            type: "img",
            src: `Booths/AstraZeneca booth/4.jpeg`,
          },

        ],
      }, {
        title: `Synagis booth 2`,
        description: ``,
        media: [
          {
            type: "img",
            src: `Booths/Synagis booth 2/1.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth 2/2.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth 2/3.jpeg`,
          },
          {
            type: "img",
            src: `Booths/Synagis booth 2/4.jpeg`,
          },

        ],
      }

    ],
  },
  Events: {
    pages: [
      {
        title: `Allergan Cycle Meeting 2023 in Egypt`,
        description: ` `,
        media: [
          { type: "img", src: `events/Allergan-Cycle-Meeting/2.gif` },
          { type: "img", src: `events/Allergan-Cycle-Meeting/1.gif` },
          { type: "img", src: `events/Allergan-Cycle-Meeting/3.jpg` },
          { type: "img", src: `events/Allergan-Cycle-Meeting/4.jpg` },
          { type: "img", src: `events/Allergan-Cycle-Meeting/5.jpg` },
          { type: "img", src: `events/Allergan-Cycle-Meeting/6.png` },

          { type: "img", src: `events/Allergan-Cycle-Meeting/8.jpg` },
          { type: "img", src: `events/Allergan-Cycle-Meeting/9.gif` },
        ],
      },
      {
        title: `Volite Hydroland External Launch 2023`,
        description: ` `,
        media: [
          {
            type: "img",
            src: `events/Volite-Hydroland-External-Launch-2023/1.gif`,
          },
          {
            type: "img",
            src: `events/Volite-Hydroland-External-Launch-2023/2.gif`,
          },
          {
            type: "img",
            src: `events/Volite-Hydroland-External-Launch-2023/3.gif`,
          },
          {
            type: "img",
            src: `events/Volite-Hydroland-External-Launch-2023/4.gif`,
          },
          {
            type: "img",
            src: `events/Volite-Hydroland-External-Launch-2023/5.gif`,
          },
          {
            type: "img",
            src: `events/Volite-Hydroland-External-Launch-2023/6.gif`,
          },
          {
            type: "img",
            src: `events/Volite-Hydroland-External-Launch-2023/7.gif`,
          },
          {
            type: "img",
            src: `events/Volite-Hydroland-External-Launch-2023/8.gif`,
          },
        ],
      },

      {
        title: `Volux Virtual Event`,
        description: ` `,
        media: [
          { type: "img", src: `events/Volux-Virtual-Event/2.gif` },
          { type: "img", src: `events/Volux-Virtual-Event/1.jpg` },
          { type: "img", src: `events/Volux-Virtual-Event/3.jpg` },
          { type: "img", src: `events/Volux-Virtual-Event/4.jpg` },
          { type: "img", src: `events/Volux-Virtual-Event/5.jpg` },
          { type: "img", src: `events/Volux-Virtual-Event/6.jpeg` },
          { type: "img", src: `events/Volux-Virtual-Event/7.jpg` },
        ],
      },

      {
        title: `Mastering Details Virtual Event`,
        description: ` `,
        media: [
          { type: "img", src: `events/Mastering-Details-Virtual-Event/1.jpg` },
          { type: "img", src: `events/Mastering-Details-Virtual-Event/2.jpg` },
          { type: "img", src: `events/Mastering-Details-Virtual-Event/3.jpg` },
          { type: "img", src: `events/Mastering-Details-Virtual-Event/4.jpg` },
          { type: "img", src: `events/Mastering-Details-Virtual-Event/5.jpg` },
          { type: "img", src: `events/Mastering-Details-Virtual-Event/6.jpg` },
          { type: "img", src: `events/Mastering-Details-Virtual-Event/7.jpg` },
        ],
      },

      {
        title: `Complication Management MEA`,
        description: ` `,
        media: [
          { type: "img", src: `events/Complication-Management-MEA/1.jpg` },
          { type: "img", src: `events/Complication-Management-MEA/2.jpg` },
          { type: "img", src: `events/Complication-Management-MEA/3.jpg` },
        ],
      },

      {
        title: `Allergan Scholarship ATLAS`,
        description: ` `,
        media: [
          { type: "img", src: `events/Allergan-Scholarship-ATLAS/1.jpg` },
          { type: "img", src: `events/Allergan-Scholarship-ATLAS/2.jpg` },
          { type: "img", src: `events/Allergan-Scholarship-ATLAS/3.jpg` },
          { type: "img", src: `events/Allergan-Scholarship-ATLAS/4.jpg` },
          { type: "img", src: `events/Allergan-Scholarship-ATLAS/5.jpg` },
          { type: "img", src: `events/Allergan-Scholarship-ATLAS/6.jpg` },
          { type: "img", src: `events/Allergan-Scholarship-ATLAS/7.jpg` },
        ],
      },

      {
        title: `Abbvie Cycle Meeting 2022`,
        description: ` `,
        media: [
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/1.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/2.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/3.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/4.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/5.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/6.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/7.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/8.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/9.jpg` },
          { type: "img", src: `events/Abbvie-Cycle-Meeting-2022/10.jpg` },
        ],
      },

      {
        title: `Ahead Event`,
        description: ` `,
        media: [
          { type: "img", src: `events/Ahead-Event/1.jpg` },
          { type: "img", src: `events/Ahead-Event/2.gif` },
          { type: "img", src: `events/Ahead-Event/3.jpg` },
          { type: "img", src: `events/Ahead-Event/4.jpg` },
        ],
      },

      {
        title: `Saudi Women Cancer Summit`,
        description: ` `,
        media: [
          { type: "img", src: `events/Saudi-Women-Cancer-Summit/1.jpg` },
          { type: "img", src: `events/Saudi-Women-Cancer-Summit/2.gif` },
          { type: "img", src: `events/Saudi-Women-Cancer-Summit/3.jpg` },
          { type: "img", src: `events/Saudi-Women-Cancer-Summit/4.jpg` },
          { type: "img", src: `events/Saudi-Women-Cancer-Summit/5.jpg` },
          { type: "img", src: `events/Saudi-Women-Cancer-Summit/6.jpg` },
        ],
      },

      {
        title: `Forxiga CKD Launch`,
        description: ` `,
        media: [
          { type: "img", src: `events/Forxiga-CKD-Launch/1.jpg` },
          { type: "img", src: `events/Forxiga-CKD-Launch/2.jpg` },
          { type: "img", src: `events/Forxiga-CKD-Launch/3.jpg` },
          { type: "img", src: `events/Forxiga-CKD-Launch/4.jpg` },
          { type: "img", src: `events/Forxiga-CKD-Launch/5.jpg` },
          { type: "img", src: `events/Forxiga-CKD-Launch/6.jpg` },
          { type: "img", src: `events/Forxiga-CKD-Launch/7.jpg` },
          { type: "img", src: `events/Forxiga-CKD-Launch/8.jpg` },
        ],
      },

      {
        title: `Lynparza Launch Event in PSR Ovarian Cancer`,
        description: ` `,
        media: [
          { type: "img", src: `events/Dare-To-Dream-March/1.jpg` },
          { type: "img", src: `events/Dare-To-Dream-March/2.jpg` },
          { type: "img", src: `events/Dare-To-Dream-March/3.jpg` },
          { type: "img", src: `events/Dare-To-Dream-March/4.jpg` },
          { type: "img", src: `events/Dare-To-Dream-March/5.jpg` },
        ],
      },

      {
        title: `Lynparza Launch Event in Prostate Cancer`,
        description: ` `,
        media: [
          { type: "img", src: `events/Prostate-Cancer-Event/1.jpg` },
          { type: "img", src: `events/Prostate-Cancer-Event/2.jpg` },

          { type: "img", src: `events/Prostate-Cancer-Event/4.jpg` },
          { type: "img", src: `events/Prostate-Cancer-Event/5.jpg` },
          { type: "img", src: `events/Prostate-Cancer-Event/6.jpg` },
          { type: "img", src: `events/Prostate-Cancer-Event/7.jpg` },
        ],
      },

      {
        title: `Nissan Sunny 2020 Launch Event`,
        description: ` `,
        media: [
          { type: "img", src: `events/nissan-sunny-2020-launch-event/1.jpg` },
          { type: "img", src: `events/nissan-sunny-2020-launch-event/2.jpg` },
          { type: "img", src: `events/nissan-sunny-2020-launch-event/3.jpg` },
          { type: "img", src: `events/nissan-sunny-2020-launch-event/4.jpg` },
        ],
      },

      {
        title: `MOH Asthma Program`,
        description: ` `,
        media: [
          {
            type: "video",
            title: "event.mp4",
            poster: "events/moh-asthma-program/1.jpg",
            src: `https://player.vimeo.com/video/491189539`,
          },

          { type: "img", src: `events/moh-asthma-program/1.jpg` },
          { type: "img", src: `events/moh-asthma-program/2.jpg` },
          { type: "img", src: `events/moh-asthma-program/3.jpg` },
        ],
      },

      {
        title: `Alahly Kit Release 2019`,
        description: ` `,
        media: [
          { type: "img", src: `events/Alahly-Kit-Release-2019/1.jpg` },
          { type: "img", src: `events/Alahly-Kit-Release-2019/2.jpg` },
          { type: "img", src: `events/Alahly-Kit-Release-2019/3.jpg` },

          { type: "img", src: `events/Alahly-Kit-Release-2019/4.jpg` },
          { type: "img", src: `events/Alahly-Kit-Release-2019/5.jpg` },
          { type: "img", src: `events/Alahly-Kit-Release-2019/6.jpg` },
        ],
      },

      {
        title: `Skechers 10 Years On The Road Event`,
        description: ` `,
        media: [
          {
            type: "img",
            src: `events/skechers-10-years-on-the-road-event/1.jpg`,
          },

          {
            type: "img",
            src: `events/skechers-10-years-on-the-road-event/2.jpg`,
          },
        ],
      },

      {
        title: `Merk Cycle Meeting`,
        description: ` `,
        media: [
          { type: "img", src: `events/Merk-Cycle-Meeting/1.jpg` },
          { type: "img", src: `events/Merk-Cycle-Meeting/2.jpg` },
          { type: "img", src: `events/Merk-Cycle-Meeting/3.jpg` },
          { type: "img", src: `events/Merk-Cycle-Meeting/4.jpg` },
          { type: "img", src: `events/Merk-Cycle-Meeting/5.jpg` },
          { type: "img", src: `events/Merk-Cycle-Meeting/6.jpg` },
        ],
      },

      {
        title: `Anta Launching Event`,
        description: ` `,
        media: [
          { type: "img", src: `events/Anta-Launching-Event/1.jpg` },
          { type: "img", src: `events/Anta-Launching-Event/2.jpg` },
          { type: "img", src: `events/Anta-Launching-Event/3.jpg` },
          { type: "img", src: `events/Anta-Launching-Event/4.jpg` },
          { type: "img", src: `events/Anta-Launching-Event/5.jpg` },
        ],
      },

      // {
      //   title: `Lilly Cycle Meeting`,
      //   description: ` `,
      //   media: [
      //     { type: "img", src: `events/Lilly-Cycle-Meeting/1.jpg` },
      //     { type: "img", src: `events/Lilly-Cycle-Meeting/2.jpg` },

      //     { type: "img", src: `events/Lilly-Cycle-Meeting/4.jpg` },
      //     { type: "img", src: `events/Lilly-Cycle-Meeting/5.jpg` },
      //     { type: "img", src: `events/Lilly-Cycle-Meeting/6.jpg` },
      //   ],
      // },
    ],
  },

  Videos: {
    pages: [
      {
        title: `Novo Nordisk Internal Interactive Video`,
        description: ` `,
        media: [
          {
            type: "video",
            title: "i1.mp4",
            poster: "videos/3.jpg",
            src: `https://player.vimeo.com/video/822450517`,
          },
        ],
      },

      {
        title: `Bone Matters Interactive Video`,
        description: ` `,
        media: [
          {
            type: "video",
            title: "i2.mp4",
            poster: "videos/1.jpg",
            src: `https://player.vimeo.com/video/822444626`,
          },
        ],
      },

      {
        title: `Save Hearts Interactive Video`,
        description: ` `,
        media: [
          {
            type: "video",
            title: "i3.mp4",
            poster: "videos/2.jpg",
            src: `https://player.vimeo.com/video/822450619`,
          },
        ],
      },
    ],
  },
  // Games: {
  //   pages: [
  //     {
  //       title: `Games #1`,
  //       description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam quibusdam, perferendis eos deleniti eaque cupiditate eligendi cum quisquam? Reprehenderit obcaecati minima doloremque quidem asperiores iste adipisci earum ut porro! Nisi!`,
  //       media: [
  //         `games/10.jpg`,
  //         `games/21.jpg`,
  //         `games/22.jpg`,
  //       ],
  //     },
  //   ],
  // },

  Concepts: {
    pages: [
      {
        title: `Allergan Cycle Meeting 2023 in Egypt`,
        description: ` `,
        media: [
          { type: "img", src: `events/Allergan-Cycle-Meeting/3.jpg` },
          { type: "img", src: `events/Allergan-Cycle-Meeting/4.jpg` },
          { type: "img", src: `events/Allergan-Cycle-Meeting/5.jpg` },
        ],
      },

      {
        title: `Mastering Details Virtual Event`,
        description: ` `,
        media: [
          { type: "img", src: `events/Mastering-Details-Virtual-Event/1.jpg` },
        ],
      },

      {
        title: `Complication Management MEA`,
        description: ` `,
        media: [
          { type: "img", src: `events/Complication-Management-MEA/1.jpg` },
        ],
      },

      {
        title: `Allergan Scholarship ATLAS`,
        description: ` `,
        media: [
          { type: "img", src: `events/Allergan-Scholarship-ATLAS/1.jpg` },
        ],
      },

      {
        title: `Abbvie Cycle Meeting 2022`,
        description: ` `,
        media: [{ type: "img", src: `events/Abbvie-Cycle-Meeting-2022/1.jpg` }],
      },

      {
        title: `Ahead Event`,
        description: ` `,
        media: [
          { type: "img", src: `events/Ahead-Event/1.jpg` },
          { type: "img", src: `events/Ahead-Event/2.gif` },
        ],
      },

      {
        title: `Saudi Women Cancer Summit`,
        description: ` `,
        media: [
          { type: "img", src: `events/Saudi-Women-Cancer-Summit/1.jpg` },

          { type: "img", src: `events/Saudi-Women-Cancer-Summit/3.jpg` },
          { type: "img", src: `events/Saudi-Women-Cancer-Summit/4.jpg` },
        ],
      },

      {
        title: `Lynparza Launch Event in PSR Ovarian Cancer`,
        description: ` `,
        media: [
          { type: "img", src: `events/Dare-To-Dream-March/1.jpg` },
          { type: "img", src: `events/Dare-To-Dream-March/2.jpg` },
          { type: "img", src: `events/Dare-To-Dream-March/3.jpg` },
        ],
      },

      {
        title: `Lynparza Launch Event in Prostate Cancer`,
        description: ` `,
        media: [
          { type: "img", src: `events/Prostate-Cancer-Event/1.jpg` },
          { type: "img", src: `events/Prostate-Cancer-Event/2.jpg` },
        ],
      },

      {
        title: `Merk Cycle Meeting`,
        description: ` `,
        media: [
          { type: "img", src: `events/Merk-Cycle-Meeting/1.jpg` },
          { type: "img", src: `events/Merk-Cycle-Meeting/2.jpg` },
          { type: "img", src: `events/Merk-Cycle-Meeting/3.jpg` },

          { type: "img", src: `events/Merk-Cycle-Meeting/4.jpg` },
        ],
      },

      {
        title: `Anta Launching Event`,
        description: ` `,
        media: [
          { type: "img", src: `events/Anta-Launching-Event/1.jpg` },
          { type: "img", src: `events/Anta-Launching-Event/2.jpg` },

          { type: "img", src: `events/Anta-Launching-Event/3.jpg` },
        ],
      },
    ],
  },
};

export default data;

export const getLocalImageByName = (name) => {
  const media = require.context("../assets", true);
  return name && media(`./${name}`);
};
